import Navbar from '../../common/Navbar.vue'
import Tabbar from '../common/Tabbar.vue'
import Loading from '../../common/Loading.vue'
import LogVisit from '../../common/LogVisit.vue'
import Share from '../../common/Share.vue'
import '../../../../assets/icon/font-icon.js'
import { Tag, Icon, Image, Form, Field, Picker, Popup, Area, TreeSelect } from 'vant'
export default {
    components: {
        Navbar: Navbar,
        Tabbar: Tabbar,
        Loading: Loading,
        LogVisit: LogVisit,
        Share: Share,
        [Tag.name]: Tag,
        [Icon.name]: Icon,
        [Image.name]: Image,
        [Form.name]: Form,
        [Field.name]: Field,
        [Picker.name]: Picker,
        [Popup.name]: Popup,
        [Area.name]: Area,
        [TreeSelect.name]: TreeSelect
    },
    data () {
        return {
            loadingShow: false,
            user: {},
            wallet: {},
            regions: {},
            regionName: '',
            regionShow: false,
            regionDefault: '610100',
            jobActiveId: '',
            jobActiveIndex: 1,
            jobChose: {},
            job: { text: '', id: '' },
            jobs: [{ id: '', text: '全部分类', children: [{ id: '', text: '全部分类' }] }],
            jobShow: false,
            seek: { state: 'STP' },
            form: {
                job: '',
                region: '',
                introduce: ''
            },
            page: { current: 0, size: 10, last: 0 },
            taskList: [],
            refereeList: [],
            amount: 0,
            tabActive: 'run'
        }
    },
    mounted () {
        document.title = '个人中心-工作'
        this.retrieveUser()
        this.retrieveJobTree()
        this.retrieveRegions()
        this.retrieveUserSeek()
        this.$refs.share.default('main')
        this.$refs.logVisit.createLog()
    },
    methods: {
        async retrieveWallet () {
            const { data: res } = await this.$http.post(this.UMS_URL + '/customer/wallet/retrieveWallet')
            if (res.status === '200') {
                this.wallet = res.data
            }
        },
        async retrieveJobTree () {
            var pd = { groupUpper: '4' }
            const { data: res } = await this.$http.post(this.BMS_URL + '/data/job/retrieveJobTree', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.jobs = res.data
            }
        },
        clickJobNav (index) {
            this.jobActiveIndex = index
        },
        clickJobItem (item) {
            this.job = item
            this.form.job = item.id
            this.jobShow = false
            this.seek.state = 'STP'
        },
        confirmRegion (values) {
            this.regionName = values
                .filter((item) => !!item)
                .map((item) => item.name)
                .join('-')
            this.form.region = values[1].code
            this.regionShow = false
            this.seek.state = 'STP'
        },
        async retrieveRegions () {
            var pd = { level: 3 }
            const { data: res } = await this.$http.post(this.UMS_URL + '/system/region/retrieveLevelRegion', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.regions.province_list = res.data.one
                this.regions.city_list = res.data.two
                this.regions.county_list = res.data.three
            }
        },
        recharge () {
            this.$router.push({ path: '/mde/hire/recharge', query: { type: 'USER', group: 'HIRE_USER' } })
        },
        record () {
            this.$router.push({ path: '/mde/wallet/cusRecord' })
        },
        async retrieveUser () {
            var user = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = { code: user }
            const { data: res } = await this.$http.post(this.UMS_URL + '/customer/user/retrieveUser', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.user = res.data
                this.retrieveWallet()
            }
        },
        async retrieveUserSeek () {
            var user = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = { userCode: user }
            const { data: res } = await this.$http.post(this.BMS_URL + '/hire/seek/retrieveUserSeek', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.seek = res.data
                var job = res.data.jobs[0]
                this.job = { id: job.code, text: job.name }
                this.form.job = job.code
                this.form.region = res.data.regionCode
                this.regionDefault = res.data.regionCode
                this.regionName = res.data.regionName
                this.form.introduce = res.data.introduce
                this.finished = false
                this.page.current = 0
                this.taskList = []
                this.retrieveTaskPage()
            }
        },
        async startSeek () {
            if (this.user.identity !== undefined && this.user.identity !== '') {
                var user = window.sessionStorage.getItem(this.SESSION_USER)
                var jobs = [this.form.job]
                var pd = { userCode: user, regionCode: this.form.region, jobs: JSON.stringify(jobs), introduce: this.form.introduce }
                const { data: res } = await this.$http.post(this.BMS_URL + '/hire/seek/startSeek', this.$qs.stringify(pd))
                if (res.status === '200') {
                    this.retrieveUserSeek()
                }
            } else {
                this.$dialog.confirm({
                    title: '提示信息',
                    message: '您未实名认证，是否立即认证'
                })
                    .then(() => {
                        // on confirm
                        this.userCertify()
                    })
            }
        },
        async stopSeek () {
            var pd = { code: this.seek.code }
            const { data: res } = await this.$http.post(this.BMS_URL + '/hire/seek/stopSeek', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.retrieveUserSeek()
            }
        },
        async retrieveTaskPage () {
            if (!this.finished) {
                this.loadingShow = true
                this.page.current = this.page.current + 1
                var pd = { seekCode: this.seek.code, current: this.page.current, size: this.page.size }
                const { data: res } = await this.$http.post(this.BMS_URL + '/hire/taskAPC/retrieveSeekTaskPage', this.$qs.stringify(pd))
                if (res.status === '200') {
                    this.taskList = this.taskList.concat(res.data)
                    this.page.last = res.page.last
                    if (res.data.length <= 0) {
                        this.finished = true
                    }
                }
                this.loadingShow = false
            }
        },
        detail (code) {
            this.$router.push({ path: '/mde/hire/detail', query: { taskCode: code } })
        },
        userCertify () {
            this.$router.push({ path: '/mde/hire/userCertify' })
        },
        publish () {
            this.$router.push({ path: '/mde/recruit/publishLabor' })
        },
        order () {
            this.$router.push({ path: '/mde/recruit/order' })
        },
        recmd () {
            this.$router.push({ path: '/mde/main/recmd' })
        },
        back () {
            this.$router.push({ path: '/mde/hire/home' })
        }
    }
}
