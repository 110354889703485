<template>
  <div class="page">
    <Navbar title="个人中心" type="SMP" :callback="back" />
    <div class="simple">
      <van-row class="top">
        <van-col span="6"
          ><van-icon name="user-circle-o" size="50px" /><van-tag
            type="primary"
            :color="COLOR_M"
            ><van-icon name="edit" /> 上传图像</van-tag
          ></van-col
        >
        <van-col span="18">
          <van-row class="user bdd">
            <van-col span="16" class="name"
              >个人信息：{{ user.name }}-{{ user.age }}岁
            </van-col>
            <van-col span="8">
              <van-tag type="primary" :color="COLOR_M" @click="userCertify"
                ><van-icon name="edit" /> 修改</van-tag
              >
            </van-col>
          </van-row>
          <van-row class="idnum">
            <van-col span="24">身份证号：{{ user.identity }} </van-col>
          </van-row>
        </van-col>
      </van-row>
      <van-row class="wallet">
        <van-col span="5"
          ><svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-beans-s"></use></svg
        ></van-col>
        <van-col span="10" class="amount"
          >{{ wallet.beanBalance }}<span class="text">(金豆)</span></van-col
        >
        <van-col span="9" class="more"
          ><van-button
            plain
            :color="COLOR_S1"
            icon="balance-list-o"
            size="mini"
            @click="record"
            >明细</van-button
          ><van-button
            plain
            :color="COLOR_S2"
            icon="cash-back-record"
            size="mini"
            @click="recharge"
            >充值</van-button
          ></van-col
        >
      </van-row>
    </div>
    <div class="demand">
      <van-form>
        <van-row class="title">
          <van-col span="24" class="text"
            ><van-icon name="notes-o" /> 个人工作喜好
            <van-tag type="primary" :color="COLOR_M" v-if="seek.state === 'NML'"
              ><van-icon name="underway-o" />&nbsp;正在找工作中</van-tag
            ><van-tag
              type="primary"
              :color="COLOR_S1"
              v-if="seek.state === 'STP'"
              ><van-icon name="underway-o" />&nbsp;已停止找工作</van-tag
            ></van-col
          >
        </van-row>
        <van-field
          v-model="job.text"
          label="工种岗位"
          readonly
          clickable
          placeholder="选择你擅长或想从事的工种"
          input-align="center"
          @click="jobShow = true"
        />
        <van-popup :show="jobShow" position="bottom">
          <van-row class="close">
            <van-col :span="24"
              ><van-icon name="close" size="25" @click="jobShow = false" />
            </van-col>
          </van-row>
          <van-tree-select
            :active-id="jobActiveId"
            :main-active-index="jobActiveIndex"
            :items="jobs"
            @click-nav="clickJobNav"
            @click-item="clickJobItem"
          />
        </van-popup>
        <van-field
          v-model="regionName"
          label="工作城市"
          readonly
          clickable
          placeholder="选择你想工作的城市"
          input-align="center"
          @click="regionShow = true"
        />
        <van-popup :show="regionShow" position="bottom">
          <van-area
            :value="regionDefault"
            :area-list="regions"
            :columns-num="2"
            @confirm="confirmRegion"
            @cancel="regionShow = false"
          />
        </van-popup>
        <van-field
          v-model="form.introduce"
          rows="2"
          autosize
          label="自我介绍"
          type="textarea"
          maxlength="200"
          placeholder="请填您擅长的工作或特长，例如：从事厨师工作10年"
          show-word-limit
        />
      </van-form>
      <div class="tips color-s1">
        提示：点击开始找工作，系统会把您推荐给更多的雇主，帮您找到更多的工作机会
      </div>
      <van-row class="btn">
        <van-col span="24"
          ><van-button
            :color="COLOR_M"
            icon="records"
            size="small"
            v-if="seek.state === 'STP'"
            @click="startSeek()"
            >立即开始找工作</van-button
          ><van-button
            :color="COLOR_S1"
            icon="records"
            size="small"
            @click="stopSeek()"
            v-if="seek.state === 'NML'"
            >立即停止找工作</van-button
          ></van-col
        >
      </van-row>
    </div>
    <!-- <div class="advice">
      <van-image
        class="img"
        :src="require('../../../../assets/images/train/adv-01.jpg')"
      ></van-image>
    </div> -->
    <div class="task">
      <van-row class="title">
        <van-col span="24" class="text"
          ><van-icon name="notes-o" /> 智能推荐工作</van-col
        >
      </van-row>
      <div class="task-list">
        <van-row
          class="task bdc-b1s-gray"
          v-for="task in taskList"
          :key="task.code"
          @click="detail(task.code)"
        >
          <van-col span="24" class="content">
            <van-row>
              <van-col span="24" class="name text-short"
                ><van-tag :color="COLOR_M" v-if="task.state === 'NML'"
                  >进行中</van-tag
                ><van-tag :color="COLOR_S1" v-if="task.state === 'CMP'"
                  >已招满</van-tag
                >
                {{ task.name }}</van-col
              >
            </van-row>
            <van-row>
              <van-col span="24" class="label">
                <span
                  v-for="item in task.labels"
                  :key="item.name"
                  class="item bgc-gray"
                  >{{ item.name }}</span
                >
              </van-col>
            </van-row>
            <van-row>
              <van-col span="14" class="time"
                ><van-icon name="underway-o" />&nbsp;{{ task.createTime }}
              </van-col>
              <van-col span="10" class="salary fc-cyan"
                ><span v-if="task.salaryMin > 1000"
                  >{{ (task.salaryMin / 1000).toFixed(1) }}K-{{
                    (task.salaryMax / 1000).toFixed(1)
                  }}K</span
                ><span v-if="task.salaryMin < 1000"
                  >{{ task.salaryMin }}-{{ task.salaryMax }}</span
                >/<span v-if="task.salaryType === 'YER'">年</span>
                <span v-if="task.salaryType === 'MOT'">月</span>
                <span v-if="task.salaryType === 'DAY'">日</span></van-col
              >
            </van-row>
            <van-row>
              <van-col :span="18" class="company">
                <span
                  ><van-icon name="newspaper-o" />&nbsp;{{ task.supplier }}
                  <van-tag :color="COLOR_S2" v-if="task.supplierType === 'DRT'"
                    >直营</van-tag
                  ></span
                ></van-col
              >
              <van-col span="6" class="amount"
                ><van-icon name="friends-o" />&nbsp;{{ task.count }}人</van-col
              >
            </van-row>
            <!-- <van-row>
              <van-col :span="18" class="address">
                <van-icon name="location-o" />&nbsp;{{ task.address }}</van-col
              >
              <van-col :span="6" class="distance"
                >距我{{ task.distance }} KM</van-col
              >
            </van-row> -->
          </van-col>
        </van-row>
        <van-row v-if="taskList.length <= 0">
          <van-col span="24" class="no-record">
            <van-image
              width="103"
              height="103"
              :src="require('../../../../assets/images/home/no-record.png')"
            ></van-image>
          </van-col>
        </van-row>
      </div>
    </div>
    <Loading :show="loadingShow" />
    <Tabbar active="seek" />
    <LogVisit ref="logVisit" module-type="HRE" module-label="HRE_SEEK_INDEX" />
    <Share ref="share" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
